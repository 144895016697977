<template>
  <div class="auth-wrapper auth-v2">
    <b-modal
      v-model="showTerms"
      title="Terminos y Condiciones de Uso"
      cancel-title="No!"
      ok-title="Aceptar"
      @ok="terms = true"
      @cancel="terms = false"
    >
      <terms></terms>
    </b-modal>
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- TODO: cambiar logo -->
        <vuexy-logo />

        <h1 class="brand-text text-primary ml-1">Stories</h1>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> Registro de usuario </b-card-title>
          <!-- TODO: texto secundario -->
          <b-card-text class="mb-2"> Crea tu cuenta </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2">
              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="nombre@ejemplo.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Password">
                <validation-provider #default="{ errors }" name="Password" rules="required|min:5">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- politicas -->
              <b-form-group>
                <b-form-checkbox id="register-privacy-policy" v-model="terms" name="checkbox-terms">
                  Al crear tu cuenta estás de acuerdo con los
                  <b-link @click="showTerms = true"> términos de uso </b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button variant="primary" block :disabled="invalid || $apiCall.state || !terms" type="submit" @click.prevent="checkreCaptcha">
                Crear Cuenta
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Ya tenés cuenta?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Ir a Login</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormCheckbox,
  BModal
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

localize('es')

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    // validations
    ValidationProvider,
    ValidationObserver,

    terms: () => import(/* webpackChunkName: "terms" */ '@/views/components/terms.vue')
  },
  metaInfo: {
    title: 'Registro',
    titleTemplate: '%s | Crea tu cuenta'
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // TODO: cambiar imagen
      sideImg: require('@/assets/images/pages/register-v2.svg'),

      terms: true,
      showTerms: false,
      // fbSignInParams: {
      //   scope: 'email',
      //   return_scopes: true
      // },
      userEmail: '',
      password: '',
      status: '',

      // validation
      required,
      email
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // TODO: cambiar imagen
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImgloginForm
      }
      return this.sideImg
    }
  },
  methods: {
    checkreCaptcha() {
      const self = this
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          self.$noti.info(`Un momento, revisamos tus credenciales por seguridad...!`)

          // eslint-disable-next-line no-undef
          grecaptcha.ready(function reCaptcha() {
            // eslint-disable-next-line no-undef
            grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_TOKEN, { action: 'submit' }).then(async function getToken(token) {
              self.$store
                .dispatch('userStore/create', {
                  reCaptcha: token,
                  item: {
                    email: self.userEmail,
                    password: self.password
                  }
                })
                .then(() => {
                  // self.trackGA(self.userEmail)
                  localStorage.setItem('sf-session-first', 'new')
                  self.$router.push('/home').catch(() => {})
                  self.$noti.info(`Bienvenid@ !!!`)
                })
                .catch(err => self.$noti.error(err.toString()))
            })
          })
        } else this.$noti.warn('Debés llenar el formulario')
      })
    }
    // trackGA(email) {
    //   // https://developers.google.com/analytics/devguides/collection/gtagjs/events
    //   this.$gtag.event('sign_up', {
    //     // 'event_category': 'user',
    //     // 'event_label': 'New User Register',
    //     'value': email
    //   })
    // }
  }
}
/* eslint-disable global-require */
</script>
<style scoped>
.grecaptcha-badge {
  visibility: visible;
}
</style>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
